import { useState, useEffect } from 'react';
import { supabase } from '../config/supabaseClient';
import toast from 'react-hot-toast';
import imageCompression from 'browser-image-compression';

export const usePublicRecommendForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    unit: '',
    postal_code: '',
    city: '',
    country: '',
    country_id: null,
    province: '',
    prov_id: null,
    description: '',
    website: '',
    phone: '',
    email: '',
    category: '',
    cat_id: null,
    sub_category: '',
    sub_cat_id: null,
    facebook: '',
    instagram: '',
    google_maps_url: '',
    photo: null,
    lat: null,
    lng: null,
  });

  const [nameError, setNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [filteredProvinces, setFilteredProvinces] = useState([]);
  const [bannedStores, setBannedStores] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: categoriesData, error: categoriesError } = await supabase
          .from('categories')
          .select('id,name')
          .order('name');

        if (categoriesError) {
          console.error('Error fetching categories:', categoriesError);
          throw categoriesError;
        }

        console.log('Categories loaded:', categoriesData);
        setCategories(categoriesData || []);

        const [subcategoriesData, countriesData, provincesData, bannedStoresData] = await Promise.all([
          supabase.from('subcategories').select('*'),
          supabase.from('countries').select('*'),
          supabase.from('provinces').select('*'),
          supabase.from('ban').select('store_name'),
        ]);

        setSubcategories(subcategoriesData.data || []);
        setCountries(countriesData.data || []);
        setProvinces(provincesData.data || []);
        setBannedStores(bannedStoresData.data?.map(item => item.store_name.toLowerCase()) || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error in fetchData:', error);
        toast.error('Failed to load form data. Please try again.');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'name') {
      const lowercaseName = value.toLowerCase();
      if (bannedStores.includes(lowercaseName)) {
        setNameError('This business name is not allowed.');
        setIsDisabled(true);
      } else {
        setNameError('');
        setIsDisabled(false);
      }
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    } else if (name === 'country') {
      const selectedCountry = countries.find(c => c.name === value);
      if (selectedCountry) {
        // Filter provinces based on selected country
        const newFilteredProvinces = provinces.filter(p => p.country_id === selectedCountry.id);
        setFilteredProvinces(newFilteredProvinces);
        
        // Reset province when country changes
        setFormData(prevData => ({
          ...prevData,
          country: value,
          country_id: selectedCountry.id,
          province: '',
          prov_id: null
        }));
      }
    } else if (name === 'province') {
      const selectedProvince = provinces.find(p => p.name === value);
      if (selectedProvince) {
        setFormData(prevData => ({
          ...prevData,
          province: value,
          prov_id: selectedProvince.id
        }));
      }
    } else if (name === 'cat_id') {
      const selectedCategory = categories.find(cat => String(cat.id) === String(value));
      if (selectedCategory) {
        setFormData(prevData => ({
          ...prevData,
          category: selectedCategory.name,
          cat_id: selectedCategory.id,
          // Reset subcategory when category changes
          sub_category: '',
          sub_cat_id: null
        }));
      }
    } else if (name === 'sub_cat_id') {
      const selectedSubcategory = subcategories.find(sub => String(sub.id) === String(value));
      if (selectedSubcategory) {
        setFormData(prevData => ({
          ...prevData,
          sub_category: selectedSubcategory.name,
          sub_cat_id: selectedSubcategory.id
        }));
      }
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleDescriptionChange = (value) => {
    if (value.length > 500) {
      setDescriptionError('Description must be 500 characters or less');
      setIsDisabled(true);
    } else {
      setDescriptionError('');
      setIsDisabled(false);
    }
    setFormData(prevData => ({
      ...prevData,
      description: value
    }));
  };

  const handleWebsiteChange = (e) => {
    let value = e.target.value;
    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      value = 'https://' + value;
    }
    setFormData(prevData => ({
      ...prevData,
      website: value
    }));
  };

  const handleSocialMediaChange = (e, platform) => {
    let value = e.target.value;
    if (value && !value.includes('.com')) {
      value = `https://www.${platform}.com/${value}`;
    } else if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      value = 'https://' + value;
    }
    setFormData(prevData => ({
      ...prevData,
      [platform]: value
    }));
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setFormData(prevData => ({
      ...prevData,
      phone: value
    }));
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value.toUpperCase();
    setFormData(prevData => ({
      ...prevData,
      postal_code: value
    }));
  };

  const handleEmailChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFormData(prevData => ({
      ...prevData,
      email: value
    }));
  };

  const handleGoogleMapsUrlChange = (e) => {
    let value = e.target.value;
    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      value = 'https://' + value;
    }
    setFormData(prevData => ({
      ...prevData,
      google_maps_url: value
    }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 10 * 1024 * 1024) {
      toast.error('File size exceeds 10MB limit');
      return;
    }
    setFormData(prevData => ({
      ...prevData,
      photo: file
    }));
  };

  const resetForm = () => {
    setFormData({
      name: '',
      address: '',
      unit: '',
      postal_code: '',
      city: '',
      country: '',
      country_id: null,
      province: '',
      prov_id: null,
      description: '',
      website: '',
      phone: '',
      email: '',
      category: '',
      cat_id: null,
      sub_category: '',
      sub_cat_id: null,
      facebook: '',
      instagram: '',
      google_maps_url: '',
      photo: null,
      lat: null,
      lng: null,
    });
    setNameError('');
    setDescriptionError('');
    setIsDisabled(false);
    setFilteredProvinces([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (nameError || descriptionError) {
      toast.error('Please fix the errors before submitting.');
      return;
    }

    const requiredFields = ['name', 'address', 'city', 'country', 'province', 'cat_id'];
    const missingFields = requiredFields.filter(field => !formData[field]);
    if (missingFields.length > 0) {
      toast.error(`Please fill in all required fields`);
      return;
    }

    try {
      const slug = `${formData.name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '')}-${Date.now()}`;

      let photoUrl = null;
      if (formData.photo) {
        try {
          const options = {
            maxSizeMB: 0.05,
            maxWidthOrHeight: 664,
            useWebWorker: true,
            fileType: 'image/webp',
          };

          let compressedFile = await imageCompression(formData.photo, options);
          if (compressedFile.size > 51200) {
            compressedFile = await imageCompression(compressedFile, {
              ...options,
              maxSizeMB: 0.049,
              maxWidthOrHeight: 600,
            });
          }

          const fileName = `${slug}.webp`;
          const filePath = `public/${fileName}`;
          
          const { error: uploadError } = await supabase.storage
            .from('photos')
            .upload(filePath, compressedFile, {
              contentType: 'image/webp',
              upsert: true
            });

          if (uploadError) throw uploadError;

          const { data: urlData } = supabase.storage
            .from('photos')
            .getPublicUrl(filePath);

          photoUrl = urlData.publicUrl;
        } catch (error) {
          console.error('Error uploading photo:', error);
          toast.error('Failed to upload photo. Proceeding without photo.');
        }
      }

      const { error } = await supabase
        .from('recommendations')
        .insert([{
          ...formData,
          photo: photoUrl,
          approved: false,
          claimed: false,
          slug,
        }]);

      if (error) throw error;

      toast.success('Thank you for your recommendation! It has been submitted for approval.');
      resetForm();
    } catch (error) {
      console.error('Error submitting recommendation:', error);
      toast.error('There was an error submitting your recommendation. Please try again.');
    }
  };

  return {
    formData,
    handleChange,
    handleDescriptionChange,
    handleWebsiteChange,
    handleSocialMediaChange,
    handlePhoneChange,
    handlePostalCodeChange,
    handleEmailChange,
    handleGoogleMapsUrlChange,
    handlePhotoChange,
    handleSubmit,
    resetForm,
    categories,
    subcategories,
    countries,
    provinces: filteredProvinces,
    nameError,
    descriptionError,
    isDisabled,
    isLoading
  };
};
