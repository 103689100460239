import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Announcement from '../components/Announcement';

const Roadmap = () => {
  return (
    <>
      <Announcement />
      <div className="bg-white min-h-screen">
        <Header />
        <main className="py-12">
          <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-4xl">
            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-8">
              <h2 className="text-xl font-bold">🚨 Attention Farmer's Market Organizers 🚨</h2>
              <p>We are looking for farmer's markets interested in piloting our new site tool post-launch so we can iron out all the details for markets and vendors. If interested, please email me.</p>
            </div>
            <h1 className="text-4xl font-bold text-gray-900 mb-2 text-center">Product Roadmap</h1>
            <p className="text-sm text-gray-500 mb-8 text-center">Last updated: January 8, 2025</p>
            
            <div className="space-y-12">
              {/* Latest Updates */}
              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Latest Updates (January 2025)</h2>
                <ul className="list-disc pl-6 space-y-3 text-gray-600">
                  <li><strong>Recommendation Form Fixed:</strong> Previously unnoticed issues with the recommendation form have been resolved and are now fully functional.</li>
                  <li><strong>Farmers Market Tool Implemented:</strong> Includes market profiles, vendor listings, hours of operation, vendor types, product categories, pricing, and stock availability. Organizers can update listings and invite vendors to manage their profiles.</li>
                  <li><strong>Listings in Canada & US:</strong> Listings are now available in both Canada and the US. US coverage is still growing, and recommendations are welcome.</li>
                  <li><strong>GDPR Cookie Consent Policy:</strong> Fully implemented to ensure compliance with privacy regulations.</li>
                  <li><strong>Bilingual Support:</strong> The platform is now available in both French and English.</li>
                  <li><strong>Authentication Testing:</strong> Authentication features are in active testing, with plans to re-implement existing listings by the end of January.</li>
                  <li><strong>Claim Listings:</strong> A new feature will soon allow business owners to claim and manage their listings and photos.</li>
                </ul>
              </section>
              
              {/* Upcoming Features */}
              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Upcoming Features (v3.0) - Spring 2025</h2>
                <p className="text-gray-600 mb-4">
                  The next version will provide more robust support for businesses and consumers, including:
                </p>
                <ul className="list-disc pl-6 space-y-3 text-gray-600">
                  <li><strong>Subscription-Based Listings:</strong> Farmers, grocers, and markets can manage their profiles to keep their information current and accessible.</li>
                  <li><strong>Farmers Market Tools:</strong> Interactive maps showcasing vendor booths, products, hours, and payment methods.</li>
                  <li><strong>Grocery Score:</strong> A rating system evaluating businesses on affordability, quality, sustainability, and transparency.</li>
                  <li><strong>Marketing Tools:</strong> Digital flyers, promotions, and updates for businesses to engage directly with consumers.</li>
                  <li><strong>Analytics Dashboard:</strong> Insights to help businesses track visitor trends and engagement.</li>
                  <li><strong>Geolocation Pop-Up Feature:</strong> Real-time updates for seasonal farm stands and backyard gardeners.</li>
                  <li><strong>Farmer/Wholesaler to Grocer Connection Tool:</strong> Facilitating connections to help farmers and wholesalers get products on grocery shelves.</li>
                  <li><strong>U.S. Market Expansion:</strong> Extending our platform to include alternative grocers and growers in the United States, enabling cross-border opportunities.</li>
                </ul>
              </section>
              
              {/* Current Features */}
              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Current Features (v2.0)</h2>
                <ul className="list-disc pl-6 space-y-3 text-gray-600">
                  <li><strong>Business Directory:</strong> Comprehensive listings of independent food businesses across Canada.</li>
                  <li><strong>Interactive Map:</strong> Visual exploration of local food alternatives in your community.</li>
                  <li><strong>Crowdsourced Listings:</strong> Community-driven recommendations of local food businesses.</li>
                  <li><strong>Recommend Your Favorites:</strong> Continue sharing your business or favorite local alternatives as we prepare to launch full profile pages and advanced functionality in our next update.</li>
                </ul>
              </section>
            </div>
          </div>
        </main>
        <Footer 
          customStyle={{ 
            maxWidth: '97rem', 
            margin: '0 auto'
          }} 
          textAlignLeft={true}
        />
      </div>
    </>
  );
};

export default Roadmap;
